import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Elementui from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css'
import "./assets/css/element-variables.less";
import "./assets/css/global.less";
// import './assets/css/element-variables.less'
// import uploader from 'vue-simple-uploader';
import uploader from "my-vue-bigfile";
import axios from "axios";
import defalutaxios from "axios";
import htmlToPdf from "@/utils/htmlToPdf.js";
axios.defaults.headers["Content-Type"] = "multipart/form-data";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Access-Control-Allow-Credentials"] = "true";
defalutaxios.defaults.headers["Content-Type"] = "application/json; charset=utf-8";
defalutaxios.defaults.headers["Access-Control-Allow-Origin"] = "*";
defalutaxios.defaults.headers["Access-Control-Allow-Credentials"] = "true";
Vue.prototype.$http = axios;
Vue.prototype.$https = defalutaxios;
Vue.use(uploader);
Vue.use(htmlToPdf);
import Print from "vue-print-nb";
Vue.use(Print);
// import './assets/css/lessvariable.less'
// window.sessionStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImI0Nzc4MjI3LWZlMGQtNGE1ZS1iYmI2LTNmOTRiMzA3MjRhMCIsInVzZXJuYW1lIjoiYWRtaW4ifQ.5VhuWxPeDroWq7jU2VXAStI74Vbeb3BDORvLfEPme5SpWQnoGJ6IRLmnR4qJoflD8xuFNFIPMeFSEYb4q6N-Uw')
Vue.use(Elementui);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
