import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './action'
import getters from './getters'

Vue.use(Vuex)
const state = {
    currentPage: '首页',
    currentSelectPlan: 0,
    fileListUp: [],
    currentNoticeIndex: 0,
    loginInfo: {},
    bottomchange:false
}
export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions
})