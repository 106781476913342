export default {
  currentPage(state, val = "首页") {
    state.currentPage = val;
  },
  currentSelectPlan(state, val = 0) {
    console.log(val, "currentSelectPlan");
    state.currentSelectPlan = val;
  },
  fileListUp(state, val = []) {
    state.fileListUp = val;
  },
  currentNoticeIndex(state, val = 0) {
    state.currentNoticeIndex = val;
  },
  setLoginInfo(state, obj = {}) {
    state.loginInfo = { ...state.loginInfo, ...obj };
  },
  setBottomchange(state, val = 0) {
    state.bottomchange = val;
  },
};
