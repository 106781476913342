<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeMount() {
    const userToken = ((document.cookie || "").match(/Admin-Token=(.*\b);?/) ||
      [])[1];
    const loginName = ((document.cookie || "").match(/loginName=(.*?\b);/) ||
      [])[1];

    userToken &&
      loginName &&
      this.$store.commit("setLoginInfo", {
        userToken,
        loginName,
      });
  },
};
</script>

<style lang="less" scoped>
#app {
  // 22.4.27注释 start(宽度过大，笔记本显示有问题)
  // min-width: 1920px;
  // overflow-x: auto;
  // end
  width: 100%;
  height: 100%;
  overflow: auto;
  .homeView {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
</style>
