import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const HomeView = () => import("../views/home/HomeView");
const CompBox = () => import("../views/main/CompBox");
const DownInfo = () => import("../views/down/DownInfo");
const RegView = () => import("../views/main/CompBox");
const NewsDetail = () => import("../views/news/NewsDetail");
const NewsInfo = () => import("../views/news/NewsInfo");
const NoticeInfo = () => import("../views/notice/NoticeInfo");
const BidPlanDetail = () => import("../views/notice/BidPlanDetail");
const VoteDetail = () => import("../views/notice/VoteDetail");
const AccepTanceDetail = () => import("../views/notice/AccepTanceDetail");
const RegisterView = () => import("../views/register/RegisterView");
const FindView = () => import("../views/search/FindView");

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
    component: CompBox,
    children: [
      {
        path: "/home",
        name: "homeview",
        component: HomeView,
      },
      {
        path: "/find",
        name: "findview",
        component: FindView,
      },
      // {
      //     path: '/xwdtinfo',
      //     name: 'xwdtinfo',
      //     component: NewsInfo,
      // }, {
      //     path: '/downinfo',
      //     name: 'downinfo',
      //     component: DownInfo,
      // },
      // {
      //     path: '/bidplandetail',
      //     name: 'bidplandetail',
      //     component: BidPlanDetail,
      // },
      // {
      //     path: '/votedetail',
      //     name: 'votedetail',
      //     component: VoteDetail,
      // }, {
      //     path: '/acceptancedetail',
      //     name: 'acceptancedetail',
      //     component: AccepTanceDetail,
      // }, {
      //     path: '/newsinfo',
      //     name: 'newsinfo',
      //     component: NewsDetail,
      // }, {
      //     path: '/gisterconstrcut',
      //     name: 'register',
      //     component: RegisterView,
      // },
    ],
  },
];

let scrollBehavior = function (to, from, savedPosition) {
  return { x: 0, y: 0 };
};
const router = new VueRouter({
  mode: "history",
  // 未生效
  scrollBehavior,
  routes,
});

export default router;
